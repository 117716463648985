<template>
  <DialogBase
    visible
    ref="dialog"
    :title="Title"
    icon="mdi-information"
    maxWidth
    complete
    close
    @clickComplete="complete(true)"
    @clickClose="close(false)"
  >
    <v-form v-model="valid" ref="form">
      <!-- {{ edit }} -->
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model.number="packageMeisaiNo"
            label="パッケージ明細番号"
            type="number"
            outlined
            prepend-icon="mdi-numeric"
            disabled
          ></v-text-field>
          <v-text-field
            v-model="workPattern"
            label="作業内容"
            outlined
            prepend-icon="mdi-toolbox"
            :rules="[Rules.Required]"
            maxlength="255"
          ></v-text-field>
          <v-text-field
            v-model="floor"
            label="フロア"
            outlined
            prepend-icon="mdi-transit-connection-variant"
            maxlength="255"
          ></v-text-field>
          <v-text-field
            v-model="area"
            label="エリア"
            outlined
            prepend-icon="mdi-home-map-marker"
            maxlength="255"
          ></v-text-field>
          <v-autocomplete
            v-model="selectcycle"
            :items="cycles"
            label="作業サイクル"
            :rules="[Rules.Required]"
            outlined
            item-text="name"
            item-value="code"
            prepend-icon="mdi-sync-circle"
            return-object
          ></v-autocomplete>
          <v-row no-gutters v-for="(item, i) in workareas" :key="i">
            <v-text-field
              v-model="item.workarea"
              :label="'作業箇所  ' + (i + 1)"
              outlined
              prepend-icon="mdi-close"
              @click:prepend="clickDelRow(i)"
              required
              :rules="[Rules.Required]"
            />
            <v-text-field
              v-model.number="item.workareasuu"
              label="数量"
              type="number"
              outlined
              prepend-icon="mdi-numeric"
            ></v-text-field>
            <v-autocomplete
              v-model="item.selectsuuKbn"
              :items="suuKbns"
              label="数量種類"
              clearable
              outlined
              item-text="name"
              item-value="code"
              prepend-icon="mdi-format-list-bulleted"
              return-object
            ></v-autocomplete>
          </v-row>
          <v-btn rounded color="primary" small @click="addrow">
            <v-icon>mdi-plus</v-icon>作業箇所を追加</v-btn
          >
        </v-col>
      </v-row>
    </v-form>
  </DialogBase>
</template>

<script>
import Common from "@/mixins/common";
import Dialog from "@/mixins/dialog";
import Forms from "@/mixins/forms";
import Api from "@/mixins/api";
import ShowDialogs from "@/mixins/showDialogs";
import PackageMeisai from "@/models/PackageMeisai";
import { CODE_GROUP as CodeGroup, NOT_SET_CODE as NotSetCode } from "@/defines";

const Shozokus = [{ name: "", code: "" }];
const Shifts = [];

export default {
  name: "PackageMeisaiDialog",
  mixins: [Common, Dialog, Forms, Api, ShowDialogs],

  computed: {
    Title() {
      return "パッケージ明細登録・編集";
    }
  },
  data() {
    return {
      packageMeisaiNo: null,
      workPattern: null,
      area: null,
      floor: null,
      workareas: [{ workarea: "", workareasuu: "", selectsuuKbn: "" }],
      cycles: [],
      selectcycle: { code: "", name: "" },
      suuKbns: [],
      selectsuuKbn: { code: "", name: "" }
    };
  },
  methods: {
    close(result) {
      this.resolve(result);
      this.$close();
    },
    async complete() {
      console.log("complete");

      if (!this.$refs.form.validate()) return;

      try {
        let wkarea = "";
        let wkareasuu = "";
        let wkareakbn = "";
        let errorflag = false;
        this.workareas.forEach(e => {
          if (wkarea !== "" && wkarea != undefined) {
            wkarea = wkarea + ",";
            if (wkareasuu == undefined) {
              wkareasuu = "" + ",";
            } else {
              wkareasuu = wkareasuu + ",";
            }
            wkareakbn = wkareakbn + ",";
            /*if (wkareakbn !== "" && wkareakbn != undefined) {
              wkareakbn = wkareakbn + ",";
            } else {
              wkareakbn = wkareakbn + ",";
            }*/
          }
          wkarea = wkarea + e.workarea;
          if (e.workareasuu != undefined && e.workareasuu != "undefined") {
            wkareasuu = wkareasuu + e.workareasuu;
          } else {
            wkareasuu = wkareasuu + "";
          }

          let flg = false;
          // 数量種類のセット

          if (e.selectsuuKbn != null && e.selectsuuKbn.code != undefined) {
            wkareakbn = wkareakbn + e.selectsuuKbn.code;
          } else if (e.selectsuuKbn != null && e.selectsuuKbn != "") {
            wkareakbn = wkareakbn + e.selectsuuKbn;
          } else {
            wkareakbn = wkareakbn + "";
            flg = true;
          }

          //数量と数量種類は、必ず対となる
          //数量(null or [0])の時、数量種類は、nullのみ可能
          //数量が1以上の場合は、数量種類が必須
          if (
            e.workareasuu != undefined &&
            e.workareasuu != "" &&
            e.workareasuu > 0
          ) {
            if (flg === true) {
              //this.$error("数量を入力した場合は、数量種類を選択してください。");
              errorflag = true;
            }
          } else {
            if (flg === false) {
              //this.$error("数量がない場合は、数量種類は選択しないでください。");
              errorflag = true;
            }
          }
        });

        if (errorflag === true) {
          this.$error(
            "数量と数量種類は両方入力または、両方とも未入力にしてください。"
          );
          return;
        }

        let display = this.workPattern;
        if (this.floor !== "") {
          display = display + "-" + this.floor;
        }
        if (this.area !== "") {
          display = display + "-" + this.area;
        }

        const cycle = [""];
        console.log(this.selectcycle.code);
        if (
          this.selectcycle.code != null &&
          this.selectcycle.code != undefined &&
          this.selectcycle.code != ""
        ) {
          cycle[0] = Number(this.selectcycle.code);
        }

        const result = new PackageMeisai(
          this.packageMeisaiNo,
          this.workPattern,
          this.floor,
          this.area,
          wkarea,
          wkareasuu,
          wkareakbn,
          cycle[0],
          display
        );

        this.$close(result);
      } catch (e) {
        console.log("complete error", e);
        this.$error(e.message);
      } finally {
        this.$unloading();
      }
    },
    addrow() {
      this.workareas.push({ workarea: "", workareasuu: "", selectsuuKbn: "" });
    },
    async clickDelRow(i) {
      if (this.workareas.length > 1) {
        if (this.workareas.workarea) {
          const msg = `作業箇所 ${i + 1} を削除します。<br>よろしいですか？`;
          if (!(await this.$deleteConfirm(msg))) return;
        }
        this.workareas.splice(i, 1);
      }
    },
    async getCodeMasterItems() {
      console.log("getExist");
      this.codeMasterItems = await this.$get(this.Paths.codeMasterPulldown);
    },
    getPullDownItems() {
      console.log("getPullDownItems");
      let filter = [];

      filter = this.codeMasterItems.filter(e => e.refcode === CodeGroup.CYCLE);
      this.cycles = filter.filter(e => e.code !== NotSetCode.CYCLE);

      let f = [];
      f = this.codeMasterItems.filter(e => e.refcode === CodeGroup.SUUKBN);
      this.suuKbns = f.filter(e => e.code !== NotSetCode.SUUKBN);
    }
  },
  async created() {
    console.log("created", this.args);
    const args = this.args;
    const index = this.args.index;

    await this.getCodeMasterItems();
    this.getPullDownItems();

    if (!this.$isEmpty(args)) {
      if (args.packageMeisaiNo != null) {
        this.packageMeisaiNo = args.packageMeisaiNo;
        this.workPattern = args.workPattern;
        this.floor = args.floor;
        this.area = args.area;

        const Cycle = args.cycle == null ? "" : String(args.cycle);
        const filterCycle = this.cycles.filter(e => e.code === Cycle);
        if (filterCycle.length > 0) {
          this.selectcycle.code = filterCycle[0].code;
          this.selectcycle.name = filterCycle[0].name;
        }

        const SuuKbn =
          args.workareaSuuKbn == null ? "" : String(args.workareaSuuKbn);
        /*const filterSuuKbn = this.suuKbns.filter(e => e.code === SuuKbn);
        if (filterSuuKbn.length > 0) {
          this.selectsuuKbn.code = filterSuuKbn[0].code;
          this.selectsuuKbn.name = filterSuuKbn[0].name;
        }*/

        if (args.workarea !== undefined) {
          const wk = args.workarea.split(",");
          const wks = args.workareasuu.split(",");
          const wksk = args.workareasuukbn.split(",");
          this.workareas.splice(0, 1);
          /*wk.forEach(e => {
            this.workareas.push({ workarea: e });
          });*/

          for (let i = 0; i < wk.length; i++) {
            const wknm = wk[i];
            const wkss = wks[i] == "undefined" ? "" : wks[i];
            const code = wksk[i];
            this.workareas.push({
              workarea: wknm,
              workareasuu: wkss,
              selectsuuKbn: code
            });
          }
        }
      } else {
        if (index !== null && index !== "") {
          this.packageMeisaiNo = index;
        } else {
          this.packageMeisaiNo = "1";
        }
      }
    }
  }
};
</script>

<style scoped>
.margin-row {
  margin-top: 0px;
}
.dialog-padding {
  padding-top: 0px;
}
</style>
