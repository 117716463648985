export default class PackagesMeisai {
  packageMeisaiNo: string;
  workPattern: string;
  floor: string;
  area: string;
  workarea: string;
  workareasuu: string;
  workareasuukbn: string;
  cycle: string;
  display: string;
  constructor(
    packageMeisaiNo = "",
    workPattern = "",
    floor = "",
    area = "",
    workarea = "",
    workareasuu = "",
    workareasuukbn = "",
    cycle = "",
    display = ""
  ) {
    this.packageMeisaiNo = packageMeisaiNo;
    this.workPattern = workPattern;
    this.floor = floor;
    this.area = area;
    this.workarea = workarea;
    this.workareasuu = workareasuu;
    this.workareasuukbn = workareasuukbn;
    this.cycle = cycle;
    this.display = display;
  }
}
