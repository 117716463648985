export default class Packages {
  hojinCd: string;
  hospitalCd: string;
  packageNo: string;
  startdate: string;
  cycle: string;
  monthcount: string;
  daycount: string;
  weekday: string;
  monday: string;
  tuesday: string;
  wednesday: string;
  thursday: string;
  friday: string;
  saturday: string;
  sunday: string;
  nholiday: string;
  week1: string;
  week2: string;
  week3: string;
  week4: string;
  week5: string;
  nationalHoliday: string;
  ninsuu: string;
  startTime: string;
  endTime: string;
  company: string;
  isValid: string;
  meisai: Array<{
    packageMeisaiNo: string;
    workPattern: string;
    floor: string;
    area: string;
    workarea: string;
    cycle: string;
    index: string;
  }>;
  constructor(
    hojinCd = "",
    hospitalCd = "",
    packageNo = "",
    startdate = "",
    cycle = "",
    monthcount = "",
    daycount = "",
    weekday = "",
    monday = "",
    tuesday = "",
    wednesday = "",
    thursday = "",
    friday = "",
    saturday = "",
    sunday = "",
    nholiday = "",
    week1 = "",
    week2 = "",
    week3 = "",
    week4 = "",
    week5 = "",
    nationalHoliday = "",
    ninsuu = "",
    starttime = "",
    endtime = "",
    company = "",
    isValid = "",
    meisai = []
  ) {
    this.hojinCd = hojinCd;
    this.hospitalCd = hospitalCd;
    this.packageNo = packageNo;
    this.startdate = startdate;
    this.cycle = cycle;
    this.monthcount = monthcount;
    this.daycount = daycount;
    this.weekday = weekday;
    this.monday = monday;
    this.tuesday = tuesday;
    this.wednesday = wednesday;
    this.thursday = thursday;
    this.friday = friday;
    this.saturday = saturday;
    this.sunday = sunday;
    this.nholiday = nholiday;
    this.week1 = week1;
    this.week2 = week2;
    this.week3 = week3;
    this.week4 = week4;
    this.week5 = week5;
    this.nationalHoliday = nationalHoliday;
    this.ninsuu = ninsuu;
    this.startTime = starttime;
    this.endTime = endtime;
    this.company = company;
    this.isValid = isValid;
    this.meisai = meisai;
  }
}
